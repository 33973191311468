import './MyPunksList.css';
import ranks from '../json/ranks.json';
import traitValuesByWeebPunkID from '../json/traitValuesByWeebPunkID.json';
import RarityCard from './RarityCard';

export default function MyPunksList({tokenIds, hasRarity}) {
  const images = []

  if(!hasRarity){
    return (
      <div className="MyPunksListContainer">
        {tokenIds.length > 0 && <div className="MyPunksListText">My WeebPunks</div>}
        <div className="MyPunksListTokenIdsContainer">
          {tokenIds.map((tokenId, index) => {
            return (
              <div className="MyPunksListTokenId" key={"tokenId" + index}>
                {/* <img className="MyPunksListImg" src={images[index]} alt="Akamaru Main" /> */}
                {/* <img className="MyPunksListImg" src={reveal} alt="Akamaru Main" /> */}
                <img className="MyPunksListImg" src={"https://cloudflare-ipfs.com/ipfs/bafybeifs2eydbdgdwksqqisgchkx5i2bnuvnurxz4sd6ovcx2kxg26exmy/"+ tokenId +".png"} alt="Akamaru Main" />
                {/* <img className="MyPunksListImg" src={"https://cf-ipfs.com/ipfs/bafybeifs2eydbdgdwksqqisgchkx5i2bnuvnurxz4sd6ovcx2kxg26exmy/"+ tokenId +".png"} alt="Akamaru Main" /> */}
                {/* <img className="MyPunksListImg" src={"https://weebpunks.com/weebs/"+ tokenId +".png"} alt="Akamaru Main" /> */}
                <div className="MyPunksListTokenIdText">WeebPunk {tokenId}</div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
  else {      
    return (
      <div className="MyPunksListContainer">
        {tokenIds.length > 0 && <div className="MyPunksListText">My WeebPunks</div>}
        <div className="MyPunksListTokenIdsContainer">
          {tokenIds.map((tokenId, index) => {
            return (
              <RarityCard key={"tokenId" + index} tokenId={tokenId} rank={ranks[tokenId]} traits={traitValuesByWeebPunkID[tokenId]} />
            )
          })}
        </div>
      </div>
    );
  }
}