import { connect } from "get-starknet"
import { constants, shortString } from "starknet"
import { RpcProvider } from 'starknet';

export const silentConnectWallet = async () => {
  const windowStarknet = await connect({ showList: true })
  if (!windowStarknet?.isConnected) {
    await windowStarknet?.enable({
      showModal: false,
      starknetVersion: "v4",
    })
  }
  return windowStarknet
}

export const connectWallet = async () => {
  const windowStarknet = await connect({
    modalMode: "alwaysAsk",
  })
  await windowStarknet?.enable({ showModal: true, starknetVersion: "v4" })
  return windowStarknet
}

export const handleConnectWallet = async () => {
  let windowStarknet = await silentConnectWallet();
  if(!windowStarknet){
    windowStarknet = await connectWallet();
  }
  return windowStarknet;
}

export const walletAddress = async (windowStarknet) => {
  if (!windowStarknet?.isConnected) {
    return
  }
  return windowStarknet.selectedAddress
}

export async function chainId(windowStarknet) {
  if (!windowStarknet.isConnected) {
    return
  }
  try {
    return shortString.decodeShortString(await windowStarknet.provider.getChainId())
    // if(windowStarknet.id == 'argentX'){
    //   return windowStarknet.chainId
    // }
    // else if(windowStarknet.id == 'braavos'){
    //   return shortString.decodeShortString(await windowStarknet.provider.getChainId())
    //   // return shortString.decodeShortString(windowStarknet.provider.chainId)
    // }
    // else {
    //   console.error("Unknown wallet id")
    // }
  } catch {
    console.error("Failed to decode chainId")
  }
}

export const getProvider = (chainId) => {
  if(chainId == "SN_MAIN")
    return new RpcProvider({nodeUrl: "https://starknet-mainnet.public.blastapi.io/rpc/v0.5"})
  else
    return new RpcProvider({nodeUrl: "https://starknet-testnet.public.blastapi.io/rpc/v0.5"})
}