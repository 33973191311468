export const currentChain = "SN_MAIN";
export const currentChainName = "Mainnet";
export const currentChainNetwork = "mainnet-alpha";
export const nftAdrs = "0x0507e93cecf4e2b5b5cc6a9131db8dffb17f32a8c7bd5fe1011baa290ed7dcbb";
export const ethAdrs = "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7"

// export const currentChain = "SN_GOERLI";
// export const currentChainName = "Testnet";
// export const currentChainNetwork = "testnet";
// export const nftAdrs = "0x03b6238e674aeb94a7c725e6449e5a9f0ba394d99dfb980937f4dda848c29a6e";
// export const ethAdrs = "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7"
