import React, { useState, useEffect } from 'react';
import ranks from '../json/ranks.json';
import traitValuesByWeebPunkID from '../json/traitValuesByWeebPunkID.json';
import './RarityRanking.css';
import AscArrow from '../assets/AscArrow.png';
import DescArrow from '../assets/DescArrow.png';
import NoneArrow from '../assets/NoneArrow.png';

export default function RarityRanking() {

  const [isLoading, setIsLoading] = useState(true);
  const [sortByString, setSortByString] = useState('valueAsc');
  const [sortedDict, setSortedDict] = useState({});

  useEffect(() => {
    let sortedByValueRanksAsc = Object.keys(ranks).sort((a, b) => ranks[a] - ranks[b]);
    let sortedByValueRanksDesc = Object.keys(ranks).sort((a, b) => ranks[b] - ranks[a]);
    let sortedByKeyRanksAsc = Object.keys(ranks).sort((a, b) => a - b);
    let sortedByKeyRanksDesc = Object.keys(ranks).sort((a, b) => b - a);
  
    let sortedDict = {
      valueAsc: sortedByValueRanksAsc,
      valueDesc: sortedByValueRanksDesc,
      keyAsc: sortedByKeyRanksAsc,
      keyDesc: sortedByKeyRanksDesc
    }
    setSortedDict(sortedDict);
    setIsLoading(false);
  }, []);



  function handleSortByRank() {
    if (sortByString === 'valueAsc') {
      setSortByString('valueDesc');
    }
    else if (sortByString === 'valueDesc') {
      setSortByString('valueAsc');
    }
    else {
      setSortByString('valueAsc');
    }
  }

  function handleSortById() {
    if (sortByString === 'keyAsc') {
      setSortByString('keyDesc');
    }
    else if (sortByString === 'keyDesc') {
      setSortByString('keyAsc');
    }
    else {
      setSortByString('keyAsc');
    }
  }

  if (isLoading) {
    return (
      <div className="RarityRankingContainerIsLoading">
        Loading...
      </div>
    )
  }

  return (
    <div className="RarityRankingContainer">
      <div className="RarityRankingMenu">
        <div className="RarityRankingMenu">
          <div className="RarityRankingSortByContainer" onClick={handleSortByRank}>
            <div className="RarityRankingSortByText">Rank</div>
            <img className="RarityRankingSortByImg" src={sortByString === 'valueAsc' ? AscArrow : sortByString === 'valueDesc' ? DescArrow : NoneArrow} alt="AscArrow" />
          </div>
          <div className="RarityRankingSortByContainer" onClick={handleSortById}>
            <div className="RarityRankingSortByText">Id</div>
            <img className="RarityRankingSortByImg" src={sortByString === 'keyAsc' ? AscArrow : sortByString === 'keyDesc' ? DescArrow : NoneArrow} alt="AscArrow" />
          </div>
        </div>
      </div>
      <div className="RarityRankingList">
        {sortedDict[sortByString].map((tokenId, index) => {
          return (
            <div key={index} className="RarityRankingCard">
              <div className="RarityRankingCardRank">Rank {ranks[tokenId]}</div>
              <div className="RarityRankingCardId">WeebPunk {tokenId}</div>
            </div>
          );
        })}
      </div>
    </div>
  )

}


