import React, { useRef, useState } from 'react';
import './PunksPreview.css';
import punk1 from '../assets/punks/gif1.png';
import punk2 from '../assets/punks/gif2.png';
import punk3 from '../assets/punks/gif3.png';
import punk4 from '../assets/punks/gif4.png';
import punk5 from '../assets/punks/gif5.png';
import punk6 from '../assets/punks/gif6.png';
import punk7 from '../assets/punks/gif7.png';
import punk8 from '../assets/punks/gif8.png';
import punk9 from '../assets/punks/gif9.png';
import punk10 from '../assets/punks/gif10.png';
import punk11 from '../assets/punks/gif11.png';
import punk12 from '../assets/punks/gif12.png';
import punk13 from '../assets/punks/gif13.png';
import punk14 from '../assets/punks/gif14.png';
import punk15 from '../assets/punks/gif15.png';
import punk16 from '../assets/punks/gif16.png';
import punk17 from '../assets/punks/gif17.png';
import punk18 from '../assets/punks/gif18.png';
import punk19 from '../assets/punks/gif19.png';
import punk20 from '../assets/punks/gif20.png';
import punk21 from '../assets/punks/gif21.png';
import punk22 from '../assets/punks/gif22.png';
import punk23 from '../assets/punks/gif23.png';
import punk24 from '../assets/punks/gif24.png';

import slideformore from '../assets/slideformore.png';

const PunksPreview = () => {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // You can adjust the multiplier to control the scrolling speed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className="PunksPreviewAndDragForMoreContainer">
      <img className='slideForMoreImg' src={slideformore} alt='slideForMore' />
      <div
        ref={containerRef}
        className="PunksPreviewContainer"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div className="PunksPreviewImagesContainer">
          <img className="PunksPreviewImg" src={punk1} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk21} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk2} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk5} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk4} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk6} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk3} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk8} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk9} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk7} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk10} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk11} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk20} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk12} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk13} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk14} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk15} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk16} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk17} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk18} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk19} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk22} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk23} alt="PunkImg" />
          <img className="PunksPreviewImg" src={punk24} alt="PunkImg" />
        </div>
      </div>
    </div>
  );
};

export default PunksPreview;
