import './Rarity.css';
import React from "react";
import { useState } from "react";
import RaritySearch from '../Components/RaritySearch';
import RarityRanking from '../Components/RarityRanking';

export default function Rarity() {

  const [rankPanelVisible, setRankPanelVisible] = useState(false);
  const [searchPanelVisible, setSearchPanelVisible] = useState(true);

  function handleRankSelectClick() {
    setRankPanelVisible(true);
    setSearchPanelVisible(false);
  }

  function handleSearchSelectClick() {
    setRankPanelVisible(false);
    setSearchPanelVisible(true);
  }

  return (
    <div className="RarityContainer">
      <div className="RarityContainerMenu">
        <div className={rankPanelVisible ? "raritySelectedPanel" : "raritySelectPanel"} onClick={handleRankSelectClick}>Ranking</div>
        <div className={searchPanelVisible ? "raritySelectedPanel" : "raritySelectPanel"} onClick={handleSearchSelectClick}>Search</div>
      </div>
      {searchPanelVisible && <RaritySearch />}
      {rankPanelVisible && <RarityRanking />}
    </div>
  );
}