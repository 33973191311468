import './MyPunks.css';
import React from "react";
import { useState, useEffect } from "react";
import {
  connectWallet,
  chainId,
  getProvider,
} from "../lib/wallet.service"

import { currentChain } from "../constants/chain"
import WalletConnect from '../Components/WalletConnect';
import Getter from '../lib/blockchain/Getter';
import MyPunksList from '../Components/MyPunksList';

export default function MyPunks() {
  const [address, setAddress] = useState();
  const [chain, setChain] = useState();
  const [isConnected, setConnected] = useState(false);
  const [account, setAccount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [provider, setProvider] = useState();

  const [tokenIds, setTokenIds] = useState([]);

  async function getOnChainInfos(address, provider) {
    let promises = []
    promises.push(Getter.getTokenIdsOf(address, provider))
    let res = await Promise.all(promises)
    setTokenIds(res[0])
  }


  async function setWalletInfos(wallet){
    setAddress(wallet.selectedAddress)

    let chainIdVal = await chainId(wallet)

    setChain(chainIdVal)
    if(chainIdVal == currentChain){
      let providerVal = getProvider(chainIdVal)
      setProvider(providerVal)
      getOnChainInfos(wallet.selectedAddress, providerVal)
    }
    setConnected(wallet.isConnected)
    if (wallet.account) {
      setAccount(wallet.account)
    }
  }

  async function handleConnectWallet() {
    setIsLoading(true)
    let wallet = await connectWallet()
    if(wallet) {
      await setWalletInfos(wallet)
    }
    setIsLoading(false)
  }

  if(isLoading){
    return (
      <div className="MintContainer">
          Loading...
      </div>
    )
  }


  return (
    <div className="MyPunksContainer">
      <WalletConnect className='walletConnectContainer' isConnected={isConnected} chain={chain} address={address} handleConnectWallet={handleConnectWallet} />
      {isConnected && chain === currentChain &&
        <MyPunksList tokenIds={tokenIds} hasRarity={true} />
      }
    </div>

  );
}