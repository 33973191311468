import freeMintsDict from "./data/freeMintsDict.json";
import freeMintsProofsDict from "./data/freeMintsProofs.json";
import discountsDict from "./data/discountsDict.json";
import discountsProofsDict from "./data/discountsProofs.json";
import holdersRanksDict from "./data/holdersRanksDict.json";
import { formatAddress } from "../lib/address.service";


export default class WhitelistHandler {

  static getFreeMints(address) {
    return freeMintsDict[formatAddress(address)]
  }

  static getFreeMintsProof(address) {
    return freeMintsProofsDict[formatAddress(address)]
  }

  static getDiscount(address) {
    return discountsDict[formatAddress(address)]
  }

  static getDiscountProof(address) {
    return discountsProofsDict[formatAddress(address)]
  }

  static getHolderRank(address) {
    return holdersRanksDict[formatAddress(address)]
  }
}