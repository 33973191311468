import React, { useState } from 'react';
import './Eligibility.css';
import WhitelistHandler from '../whitelist/WhitelistHandler';


const Eligibility = () => {

  const [searchText, setSearchText] = useState('');
  const [isHolder, setIsHolder] = useState(false);
  const [eligibleFreeMints, setEligibleFreeMints] = useState(false);
  const [eligibleDiscount, setEligibleDiscount] = useState(false);
  
  const [resultFreeMint, setResultFreeMint] = useState('');
  const [resultDiscount, setResultDiscount] = useState('');
  const [resultRank, setResultRank] = useState('')
  const discountPrices = [0.0000, 0.00087, 0.001, 0.0012, 0.0014]

  const handleSearch = () => {
    const lowerCaseSearch = searchText.toLowerCase();

    let rank = WhitelistHandler.getHolderRank(lowerCaseSearch)
    if(rank) {
      rank = "Your snapshot holder rank is " + rank;
      setIsHolder(true);
    }
    else {
      rank = "You didn't own any AKU on snapshot date";
      setIsHolder(false);
    }
    

    let freeMints = WhitelistHandler.getFreeMints(lowerCaseSearch);
    if(freeMints) {
      freeMints = "You have " + freeMints + " free mints";
      setEligibleFreeMints(true);
    }
    else {
      freeMints = "You don't have any free mint";
      setEligibleFreeMints(false);
    }
    let discount = WhitelistHandler.getDiscount(lowerCaseSearch);
    if(discount) {
      discount = "You can mint at a discount for " + discountPrices[discount] + " ETH";
      setEligibleDiscount(true);
    }
    else {
      discount = "You don't have any discount";
      setEligibleDiscount(false)
    }
    setResultDiscount(discount);
    setResultFreeMint(freeMints);
    setResultRank(rank)
  };

  return (
    <div className='eligibilityContainer'>
      <div className='searchAndResultContainer'>
        <div className='searchContainer'>
          Input your address to check your eligibility.
          <input 
            className='eligibilitySearchInput'
            type="text" 
            value={searchText} 
            onChange={(e) => setSearchText(e.target.value)} 
          />
          <button className='eligibilitySearchButton' onClick={handleSearch}>Search</button>
        </div>
        <div className='eligibilityResultContainer'>
        
        {isHolder ? <div className='resultPositiveEligibility'>{resultRank}</div> : <div className='resultNegativeEligibility'>{resultRank}</div>}
        <div className='resultAndDateContainer'>
          {eligibleFreeMints ? <div className='resultPositiveEligibility'>{resultFreeMint}</div> : <div className='resultNegativeEligibility'>{resultFreeMint}</div>}
          <div className='resultDate'>Free mint opens the 24th at 10 AM UTC</div>
        </div>
        <div className='resultAndDateContainer'>
          {eligibleDiscount ? <div className='resultPositiveEligibility'>{resultDiscount}</div> : <div className='resultNegativeEligibility'>{resultDiscount}</div>}
          <div className='resultDate'>Discount mint opens the 25th at 10 AM UTC</div>
        </div>
        <div className='resultDate'>Public mint opens the 26th at 10 AM UTC</div>
        </div>
      </div>
    </div>
  );
};

export default Eligibility;
