import React from 'react';
import { useState, useEffect } from 'react';
import './RarityCard.css';
import frequencies from '../json/frequencies.json';


export default function RarityCard({tokenId, rank, traits}) {

  // const [imageLoaded, setImageLoaded] = useState(false);

  // useEffect(() => {
  //   setImageLoaded(false)
  //   const img = new Image();
  //   img.onload = () => {
  //     setImageLoaded(true);
  //   };
  //   img.src = "https://cloudflare-ipfs.com/ipfs/bafybeifs2eydbdgdwksqqisgchkx5i2bnuvnurxz4sd6ovcx2kxg26exmy/"+ tokenId +".png";
  // }, [tokenId]);

  return (
    <div className="RarityCardContainer">
      {/* {imageLoaded ? (
        <img className="RarityPunkImg" src={"https://cloudflare-ipfs.com/ipfs/bafybeifs2eydbdgdwksqqisgchkx5i2bnuvnurxz4sd6ovcx2kxg26exmy/"+ tokenId +".png"} alt="Akamaru Main" />
      ) : (
        <div className="RarityPunkImgPlaceholder">Loading...</div>
      )} */}
      <img className="RarityPunkImg" src={require(`../assets/collection/${tokenId}.png`)} alt="Akamaru Main" />
      <div className="RarityCardTokenId">WeebPunk {tokenId}</div>
      <div className="RarityCardRarityText">Rank {rank}</div>
      <div className="FrenquenciesContainer">
        {traits.map((trait, index) => {
          return (
            <div key={index} className="RarityCardTraitContainer">
              <div className="RarityCardTraitName">{trait}</div>
              <div className="RarityCardTraitValue">{(frequencies[trait] * 100).toFixed(2)}%</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}