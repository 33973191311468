import React, { useState } from 'react';
import RarityCard from '../Components/RarityCard';
import ranks from '../json/ranks.json';
import traitValuesByWeebPunkID from '../json/traitValuesByWeebPunkID.json';
import './RaritySearch.css';

export default function RaritySearch() {

  const [searchId, setSearchId] = useState('');
  const [punkExists, setPunkExists] = useState();
  const [tokenId, setTokenId] = useState();
  const [rank, setRank] = useState();
  const [traits, setTraits] = useState();

  const handleSearchChange = (value) => {
    setSearchId(value.replace(/\D/g,''));
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  function handleSearch() {
    let rank = ranks[searchId];
    let traits = traitValuesByWeebPunkID[searchId];

    if(!rank || !traits){
      console.log('Invalid ID');
      setPunkExists(false);
    }
    else {
      setRank(rank);
      setTraits(traits);
      setTokenId(searchId);
      setPunkExists(true);
    }
  }

  return (
    <div className="raritySearchContainer">
      <div className='raritySearchAndInputContainer'>
        <div className='rarityInputTitle'>Input punk ID</div>
        <input 
          name='raritySearchInput'
          className='raritySearchInput'
          type="text" 
          value={searchId} 
          onKeyPress={handleKeyPress} 
          onChange={(e) => handleSearchChange(e.target.value)} 
        />
        <button className='raritySearchButton' onClick={handleSearch}>Search</button>
      </div>
      {punkExists == false && <div className='rarityResultContainer'>Invalid ID</div>}
      {punkExists == true && <RarityCard tokenId={tokenId} rank={rank} traits={traits} />}
    </div>
  );
}