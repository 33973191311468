import { ethAdrs, nftAdrs } from "../../constants/chain"
import { Contract } from "starknet"
import ERC20Abi from "../../constants/abi/ERC20.json"
import NFTAbi from "../../constants/abi/NFT.json"
import BigNumber from "bignumber.js";

export default class Getter {
  // static async hasWalletClaimed(selectedAddress, provider) {
  //   const contract = new Contract(ClaimerAbi, nftAdrs, provider);
  //   const res = await contract.hasClaimedWallet(selectedAddress);
  //   console.log('hasWalletClaimed : ', res);
  //   return res;
  // }

  // static async isIdClaimed(id, provider) {
  //   const contract = new Contract(ClaimerAbi, nftAdrs, provider);
  //   const res = await contract.isClaimedId(id);
  //   console.log('isIdClaimed : ', res);
  //   return res;
  // }

  static async getFreeMintsQuantity(selectedAddress, provider) {
    const contract = new Contract(NFTAbi, nftAdrs, provider);
    const res = Number(await contract.getFreeMints(selectedAddress));
    console.log('getFreeMintsQuantity : ', res);
    return res;
  }

  static async getMintedQuantity(selectedAddress, provider) {
    const contract = new Contract(NFTAbi, nftAdrs, provider);
    const res = Number(await contract.getMintedQuantity(selectedAddress));
    console.log('getMintedQuantity : ', res);
    return res;
  }

  static async getDiscount(selectedAddress, provider) {
    const contract = new Contract(NFTAbi, nftAdrs, provider);
    const res = Number(await contract.getDiscount(selectedAddress));
    console.log('getDiscount : ', res);
    return res;
  }

  static async getTotalSupply(provider) {
    const contract = new Contract(NFTAbi, nftAdrs, provider);
    const res = Number(await contract.totalSupply());
    console.log('getTotalSupply : ', res);
    return res;
  }

  static async isFreeMintRunning(provider) {
    const contract = new Contract(NFTAbi, nftAdrs, provider);
    const res = await contract.isFreeMintRunning();
    console.log('isFreeMintRunning : ', res);
    return res;
  }

  static async isPrivateSaleRunning(provider) {
    const contract = new Contract(NFTAbi, nftAdrs, provider);
    const res = await contract.isPrivateSaleRunning();
    console.log('isPrivateSaleRunning : ', res);
    return res;
  }

  static async isPublicSaleRunning(provider) {
    const contract = new Contract(NFTAbi, nftAdrs, provider);
    const res = await contract.isPublicSaleRunning();
    console.log('isPublicSaleRunning : ', res);
    return res;
  }

  static async hasFreeMinted(selectedAddress, provider) {
    const contract = new Contract(NFTAbi, nftAdrs, provider);
    const res = await contract.hasFreeMinted(selectedAddress);
    console.log('hasFreeMinted : ', res);
    return res;
  }
    

  static async getApprovedETHQuantity(selectedAddress, spender, provider) {
    const contract = new Contract(ERC20Abi, ethAdrs, provider);
    const res = new BigNumber(await contract.allowance(selectedAddress, spender));
    // console.log('getApprovedETHQuantity : ', res.toFixed(18));
    let decimals = new BigNumber("1000000000000000000")
    console.log('getApprovedETHQuantity : ', res.dividedBy(decimals).toFixed(12));
    return res.dividedBy(decimals).toFixed(0);
  }

  static async getTokenIdsOf(selectedAddress, provider) {
    const contract = new Contract(NFTAbi, nftAdrs, provider);
    const res = await contract.getTokenIdsOf(selectedAddress);
    for(let i = 0; i < res.length; i++){
      res[i] = Number(res[i])
    }
    console.log('getTokenIdsOf : ', res);
    return res;
  }

  static async getTotalSupply(provider) {
    const contract = new Contract(NFTAbi, nftAdrs, provider);
    const res = Number(await contract.totalSupply());
    console.log('totalSupply : ', res);
    return res;
  }

}