import './WeebMan.css';
import weebman from '../assets/weebman.png';
import weebmanphone from '../assets/weebmanphone.png';
import { Parallax, ParallaxLayer } from '@react-spring/parallax'

export default function WeebMan() {
  return (

    <div className="WeebManContainer">
      <ParallaxLayer className='WeebManParallaxContainer' offset={1} speed={0.7}>
        <div className="WeebManImgContainer">
          <picture>
            <source className="WeebManImg" media="(max-width: 1300px)" srcSet={weebmanphone} />
            <img className="WeebManImg" src={weebman} alt="WeebMan" />
          </picture>
        </div>
      </ParallaxLayer>
    </div>
  );
}