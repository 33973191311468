import { currentChain, currentChainName, currentChainNetwork, contractAddress } from "../constants/chain"
import { truncateAddress } from '../lib/address.service';
import "./WalletConnect.css"
export default function WalletConnect({isConnected, chain, address, handleConnectWallet}) {
  // console.log(chain)
  if(isConnected && chain !== currentChain) {
    return (
      <div className="walletContainer">
        <div className="changeNewtork">
          Please connect to {currentChainName}
        </div>
      </div>
    )
  }
  else if(isConnected) {
    return (
      <div className="walletContainer">
        <div className="walletInfos">
          <div className="walletAddress">
            Connected as {truncateAddress(address)}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="walletContainer">
      <div className="connectWalletButton" onClick={handleConnectWallet}>
        Connect Wallet
      </div>
      <p>First connect your wallet.</p>      
    </div>
  )
}