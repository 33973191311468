import './Home.css';
import Navbar from "../Components/Navbar";
import Presentation from '../Components/Presentation';
import PunksPreview from '../Components/PunksPreview';
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import WeebMan from '../Components/WeebMan';
export default function Home() {
  return (
      <div className="homeContainer">
        <Parallax pages={2}>
          <ParallaxLayer offset={0} speed={0.1}>
            <Presentation />
            <PunksPreview />
          </ParallaxLayer>
          <WeebMan />
        </Parallax>
      </div>
  );
}