import './Presentation.css';
import punk1 from '../assets/punks/gif15.png';
import weebPunks from '../assets/weebPunks.png';

export default function Presentation() {
  return (
    <div className="presentationContainer">
      <div className="presentationImagesContainer">
        <img className="presentationAkamaruImg" src={punk1} alt="Akamaru Main" />
      </div>
      <div className="presentationText">
        {/* <div className="presentationTitle">WeebPunks</div> */}
        <img className="presentationTitleImg" src={weebPunks} alt='weebPunks' />
        <div className="presentationDescription">
          <div className="presentationSmallTitle">A virgins only collection.</div>
          <div className="presentationDescriptionText">
            <div>UNDER 9000 NFTs on Starknet, 5000 exactly.</div>
            <div>According to legend, obtaining one is believed to unlock your chakras, allowing you to master the mystique of black magic.</div>
            <div>Padawans should exercise caution when holding.</div>
            <div>May induce internal monologue syndrome.</div>
          </div>
        </div>
      </div>
    </div>
  );
}