import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom'
import Home from './Pages/Home'
import Mint from './Pages/Mint';
import MyPunks from './Pages/MyPunks';
import Navbar from './Components/Navbar';
import Eligibility from './Pages/Eligibility';
import Rarity from './Pages/Rarity';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/mint" element={<Mint />} /> */}
        <Route path="/myPunks" element={<MyPunks />} />
        <Route path="/rarity" element={<Rarity />} />
        <Route path="/eligibility" element={<Eligibility />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
