import './Navbar.css';
import { HashLink as Link } from "react-router-hash-link";
import twitterIcon from '../assets/twitterIcon.png';
import telegramIcon from '../assets/telegramIcon.png';
import weebPunks from '../assets/weebPunks.png';
import { useState } from 'react';

export default function Navbar() {

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={`navbarContainer ${menuOpen ? 'menuOpen' : ''}`}>
      <div className='menuIcon' onClick={toggleMenu}>
        <div className='bar'></div>
        <div className='bar'></div>
        <div className='bar'></div>
      </div>
      <div className='navbarLinksContainer'>
        <div className="navbarLogoContainer">
          <Link className='navbarLink' to="/" >
            <img className='navbarLogo' src={weebPunks} alt='weebPunks' />
          </Link>
        </div>
        <div className='linksContainer'>
          {/* <Link className='navbarLink' to="/mint" >
            Mint
          </Link> */}
          <Link className='navbarLink' to="/myPunks" >
            My Punks
          </Link>
          <Link className='navbarLink' to="/rarity" >
            Rarity
          </Link>
          {/* https://element.market/collections/weebpunks */}
          {/* <a className='navbarLink' href="https://pyramid.market/collection/0x507e93cecf4e2b5b5cc6a9131db8dffb17f32a8c7bd5fe1011baa290ed7dcbb" target="_blank" rel="noopener noreferrer"> */}
          <a className='navbarLink' href="https://element.market/collections/weebpunks" target="_blank" rel="noopener noreferrer">
            Trade
          </a>
        </div>
        <div className="navbarSocialsContainer">
          <a href="https://twitter.com/WeebPunks"><img className='navbarIcon' src={twitterIcon} alt='twitterIcon' /></a>
          <a href="https://t.me/AkamaruStarknet"><img className='navbarIcon' src={telegramIcon} alt='telegramIcon' /></a>
        </div>
      </div>
    </div>
  );
}